import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import { StyleProvider } from "@ant-design/cssinjs";
import Room from "./pages/Room/Room";
import Rooms from "./pages/Rooms/Rooms";
import Surroundings from "./pages/Surroundings/Surroundings";
import Gallery from "./pages/Gallery/Gallery";
import Maintenance from "./pages/Maintenance/Maintenance";
import React from "react";
import webService from "./services/web.service";
import MaintenanceTag from "./components/MaintenanceTag";
import Restaurant from "./pages/Restaurant/Restaurant";
import Services from "./pages/Services/Services";
import WhereAreWe from "./pages/WhereAreWe/WhereAreWe";
import Shop from "./pages/Shop/Shop";
import Events from "./pages/Events/Events";
import Contacts from "./pages/Contacts/Contacts";
import PreventivoButton from "./components/PreventivoButton";
import WhatsappButton from "./components/WhatsappButton";

function App(props) {
  return (
    <StyleProvider hashPriority="high">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home uuid={props.uuid} />} />
          <Route
            path="/room/:id"
            element={<Room uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/rooms"
            element={<Rooms uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/surroundings"
            element={<Surroundings uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/gallery"
            element={<Gallery uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/restaurant"
            element={<Restaurant uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/services"
            element={<Services uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/where-are-we"
            element={<WhereAreWe uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/shop"
            element={<Shop uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/events"
            element={<Events uuid={props.uuid} color={props.color} />}
          />
          <Route
            path="/contact-us"
            element={<Contacts uuid={props.uuid} color={props.color} />}
          />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <PreventivoButton uuid={props.uuid} />
      <WhatsappButton uuid={props.uuid} />
    </StyleProvider>
  );
}

export default App;
