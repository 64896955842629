import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import { useNavigate } from "react-router-dom";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";
import { tcolors } from "../../config/Colors";
import { css } from "glamor";
import { STRUCTURE_NAME } from "../../config/backendConfig";

function Events({ uuid, color }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const [events, setEvents] = React.useState([
    {
      id: 1,
      title: "Cosa c’è di nuovo al museo?",
      subtitle:
        'A Rovereto l\'eleganza di Klimt, a Trento scopri il "lato selvaggio" delle città',
      image:
        "https://www.visittrentino.info/_default_upload_bucket/5875442/image-thumb__5875442__story-headerimage/01%20Gustav%20Klimt%2C%20Giuditta%20II%2C%201909%2C%20Fondazione%20Musei%20Civici%20di%20Venezia%2C%20Galleria%20Internazionale%20d%27Arte%20Moderna%20di%20Ca%27%20Pesaro_mod2.webp",
      description: `PRIMAVERA 2023 – Quest'anno diamo il benvenuto alla bella stagione con gli ori e l'eleganza di Gustav Klimt, al Mart di Rovereto. Ma non solo: se non l'avete ancora vista, sempre al Mart è stata prorogata fino al 4 giugno la grande mostra "Giotto e il Novecento": un percorso espositivo tra grandi opere d'arte del secolo scorso, per comprendere come il maestro toscano abbia influenzato l'arte nei secoli.
        Ricca e variegata invece la proposta di Trento: al MUSE, con la nuova mostra "Wild city. Storie di natura urbana" (apertura a fine marzo) e con la proroga fino al 28 maggio della mostra "Nella mente del lupo". Continua, inoltre, al Palazzo delle Albere la mostra "Dentro il colore",  sul tema della percezione fisica e mentale del colore.
        Sempre a Trento, fino al 28 maggio puoi visitare la mostra "Spettacolo", un'immersione multimediale nella storia del Trentino, ospitata negli spazi delle Gallerie, mentre il Castello del Buonconsiglio ospita la monografica sul fotografo trentino Sergio Perdomi.
        Dai un’occhiata alle nostre mostre e alle iniziative più interessanti di questi mesi!`,
      date: "01/06/2023",
    },
    {
      id: 2,
      title: "Il Festival dell’Economia torna a Trento",
      subtitle: "Dal 25 al 28 maggio Trento si tinge di arancione",
      image:
        "https://www.visittrentino.info/_default_upload_bucket/5162738/image-thumb__5162738__story-headerimage/header%20FDE_2000x900.webp",
      description: `PRIMAVERA 2023 - Anche quest’anno la città di Trento ospiterà il Festival dell’Economia per la sua 18^ edizione! Dal 25 al 28 maggio, una quattro giorni imperdibile a cui prenderanno parte relatori provenienti dal mondo accademico, economico e imprenditoriale.
        “Il Futuro del Futuro. LE SFIDE DI UN MONDO NUOVO” è il tema scelto per quest’edizione, che darà vita ad un interessante e stimolante dibattito tra importanti relatori, selezionati tra le menti più brillanti del mondo scientifico, manageriale, opinion leader di riferimento e importanti esperti delle più diverse discipline.
        Accanto al programma ufficiale, numerose saranno le iniziative pensate per il pubblico dei più giovani e delle famiglie. Il “FuoriFestival” rilancia i suoi contenuti speciali che attraversano in modo trasversale la cultura e il puro intrattenimento, nuovi linguaggi, dalla didattica alle nuove forme di comunicazione e di infotainment. Tornano così sui palchi e nelle piazze di Trento talent, creator e influencer, che si alterneranno alle dirette-evento di Radio 24, con la possibilità per il pubblico dal vivo di incontrare i propri conduttori preferiti e vivere le emozioni del dietro le quinte.`,
      date: "25/05 - 26/05",
    },
  ]);
  const { t } = useTranslation("common");

  let navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  React.useEffect(() => {
    document.title = t("titles.events", {
      structure_name: STRUCTURE_NAME,
    });
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };
  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("events.title")}
        </div>
      </div>

      <div className="max-w-7xl mx-auto flex flex-col gap-20 py-16">
        {events?.map((ev, index) => (
          <div
            className="flex flex-col lg:flex-row items-start gap-10  p-5
           transition rounded-lg"
          >
            <img
              src={ev.image}
              className="w-[200px] h-[200px] object-cover rounded-md"
              alt={"event-" + index}
            />
            <div className="flex flex-col">
              <h2
                className="text-2xl uppercase font-semibold"
                {...css({
                  color: tcolors[config.color + "-600"],
                  ":hover": {
                    color: tcolors[config.color + "-800"],
                  },
                })}
              >
                {ev.title}
              </h2>
              <h3
                className="text-lg font-semibold"
                {...css({
                  color: tcolors[config.color + "-200"],
                })}
              >
                {ev.subtitle}
              </h3>
              <p className="mt-5 text-gray-600">{ev.description}</p>
              <span className="mt-5 text-gray-300 font-semibold">
                Date: {ev.date}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy />
      <ButtonBar color={config.color} />
    </>
  );
}

export default Events;
