import React from "react";

function VideoHome({ uuid }) {
  const [link, setLink] = React.useState(
    "https://www.youtube.com/embed/oR2qc0pqMhE"
  );
  return (
    <div className="h-full w-full overflow-hidden">
      <div id="video">
        <iframe
          width="100%"
          height="100%"
          src={
            link +
            "?version=3&mute=1&autoplay=1&showinfo=0&modestbranding=1&loop=1&fs=0&controls=0&playlist=oR2qc0pqMhE"
          }
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="ytplayer"
        ></iframe>
      </div>
    </div>
  );
}

export default VideoHome;
